
import { TheMask } from 'vue-the-mask'
import { Component, Prop, Ref } from 'nuxt-property-decorator'
import SearchFormAbstract from '@simple-payments/shared-packages/classes/search-form/SearchFormAbstract'
import ErrorGrzForm from '@simple-payments/shared-components/components/search-form/ErrorGrzForm.vue'
import DocGrz from '@simple-payments/shared-packages/classes/doc-value/DocGrz'
import GrzGibddSearch from '@simple-payments/shared-packages/classes/landing/gibdd/GrzGibddSearch'
import LicencePlateInput from '../LicencePlateInput.vue'
import InfoBtn from './InfoBtn.vue'
import EmailForm from './EmailForm.vue'
import { ErrorValidation } from '~/types/ErrorValidation'
import SearchFormOfertaText from '~/components/search-form/SearchFormOfertaText.vue'

@Component({
  components: {
    SearchFormOfertaText,
    InfoBtn,
    TheMask,
    LicencePlateInput,
    EmailForm,
    ErrorGrzForm
  },
  transition: 'email'
})
export default class extends SearchFormAbstract {
  @Prop({ default: true }) showLicencePlate!: boolean
  @Ref() ctcField!: HTMLInputElement
  @Prop() dataErrorFormValidations!: ErrorValidation
  isFormSubmitted = false
  clientErrorValid: string = ''
  errorCode = 0
  showSubmitBtnSpinner: boolean = false
  formData = new DocGrz()

  onInputLicence () {
    (this.$v.formData as any).document_value.$reset()
  }

  isEmailVisible () {
    if (this.formData.document_value.length >= 8 && !this.$store.getters['user/getUser'].email?.length && !this.isFormSubmitted) {
      return true
    }
    return false
  }

  errorClientValid () {
    this.clientErrorValid = ''
    this.$v.$touch()
    // if (!this.isEmailVisible && !this.$store.getters['user/getUser'].email?.length) {
    //   this.isEmailVisible = true
    // }

    if (!this.dataErrorFormValidations.isError) {
      // @ts-ignore
      if (this.$v.formData.document_value.$error) {
        this.clientErrorValid = ' гос. номеров: <span class="error-msg__item">A 000 AA 000</span> <span class="error-msg__item">A 000 AA 00</span>'
      }
    }
  }

  getType (): string {
    return 'gibdd'
  }

  afterSubmitBehavior (): Promise<any> {
    this.showSubmitBtnSpinner = true
    const choiceInstanceSearch = new GrzGibddSearch()
    return choiceInstanceSearch.validate(this.formData).then(() => {
      this.$nuxt.$eventBus.$emit('form-with-email')
      this.errorCode = 0
      this.isFormSubmitted = true
      this.showSubmitBtnSpinner = false
      return this.$router.push({
        path: '/search/' + this.getType(),
        // @ts-ignore
        query: {
          document_type: this.formData.docType(),
          lead_source_id: window.$nuxt.$route.query.lead_source_id,
          ...this.formData
        }
      })
    }).catch((err) => {
      if (err.response.status === 429) {
        this.$route.query.grzblock = 'true'
      }
      this.errorCode = err.response.status
      this.showSubmitBtnSpinner = false
    })
  }
}
